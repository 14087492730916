import config from '../../shared/config/config';
import UiElement from '../ui-element/ui-element';

type CaptchaWidget = number | undefined;

class CaptchaProvider extends UiElement {
  private isCaptchaLoaded: boolean = false;

  private footerWidgetId?: CaptchaWidget;
  private blogWidgetId?: CaptchaWidget;
  private signupFormCaptchaId?: CaptchaWidget;

  private footerCaptchaContainer = document.querySelector('#js-footer-captcha-container');
  private blogSubscriptionBanner = document.querySelector('#js-blog-captcha-container');
  private signupFormCaptcha = document.querySelector('#js-signup-captcha-container');

  onInit() {
    window.onloadCallback = this.onloadCallback; // render all captcha widgets
    this.lazyLoadRecaptcha();
  }

  get captchaFooterWidgetId(): CaptchaWidget {
    return this.footerWidgetId;
  }

  get captchaBlogWidgetId(): CaptchaWidget {
    return this.blogWidgetId;
  }

  get captchaSignupFormWidgetId(): CaptchaWidget {
    return this.signupFormCaptchaId;
  }

  get isCaptchaReady(): boolean {
    return typeof window.grecaptcha !== 'undefined' && this.isCaptchaLoaded;
  }

  onloadCallback = () => {
    if (this.footerCaptchaContainer) {
      this.footerWidgetId = window.grecaptcha.render(this.footerCaptchaContainer as HTMLElement, {
        'sitekey': config.get('recaptchaKey'),
      });
    }

    if (this.blogSubscriptionBanner) {
      this.blogWidgetId = window.grecaptcha.render(this.blogSubscriptionBanner as HTMLElement, {
        'sitekey': config.get('recaptchaKey'),
      });
    }

    if (this.signupFormCaptcha) {
      this.signupFormCaptchaId =  window.grecaptcha.render(this.signupFormCaptcha as HTMLElement, {
        'sitekey': config.get('recaptchaKey'),
      });
    }
  };

  lazyLoadRecaptcha() {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.async = true;
    recaptchaScript.onload = () => {
      this.isCaptchaLoaded = true;
    };
    recaptchaScript.src = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit';
    document.body.appendChild(recaptchaScript);
  }
}

export default CaptchaProvider;
