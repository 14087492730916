import axios from 'axios';
import UiElement, { UiOptions } from '../../../shared/ui-element/ui-element';
import CaptchaProvider from '../../../shared/captcha-provider/captcha-provider';
import { createState } from '../../utils/state';
import Logger from '../../utils/logger/logger';
import analytics, { events } from '../../../shared/analytics';

interface BlogSubscribeBannerState {
  isLoading: boolean;
  step: string;
  validation: {
    email: string;
    confirmation: string;
    captcha: string;
  };
}

interface FormValues {
  email: string;
  confirmation: string;
  captcha: string;
}

const EMAIL_VALIDATION_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

class BlogSubscribeBanner extends UiElement<HTMLFormElement> {
  state: BlogSubscribeBannerState;

  readonly captchaProvider: CaptchaProvider;

  static inputErrorClass = '-error';

  static formValidationClass = 'subscribe-banner__form-error_active';

  static formStepActiveClass = 'subscribe-banner__step_active';

  events = {
    'submit @rootElement': 'handleFormSubmit',
    'change @ui.confirmationCheckbox': 'revalidateForm',
    'input @ui.emailInput': 'revalidateForm',
  };

  constructor(options: UiOptions, captchaProvider: CaptchaProvider) {
    super(options);

    this.captchaProvider = captchaProvider;

    this.ui = {
      form: '.js-subscribe-banner-form',
      emailInput: '.js-subscribe-banner-email-input',
      confirmationCheckbox: '.js-subscribe-banner-confirmation',
      submitBtn: '.js-subscribe-banner-submit-btn',
      emailError: '.js-subscribe-banner-email-error',
      confirmationError: '.js-subscribe-banner-confirmation-error',
      captchaError: '.js-subscribe-banner-captcha-error',
      captchaContainer: '.js-subscribe-banner-captcha-container',
    };

    const [state, subject] = createState<BlogSubscribeBannerState>(
      {
        isLoading: false,
        step: 'form',
        validation: {
          email: '',
          confirmation: '',
          captcha: '',
        },
      }
    );

    this.state = state;

    subject.subscribe(this.handleChangeState);
  }

  get formData(): FormValues {
    const { form } = this.ui;

    return {
      email: (form as HTMLFormElement)?.email?.value ?? '',
      confirmation: (form as HTMLFormElement)?.confirmation?.checked ?? false,
      captcha: this.getCaptchaResponse(),
    };
  }

  private setState = (state: Partial<BlogSubscribeBannerState>) => {
    if (!Object.keys(state).length) return;

    Object.keys(state).forEach((key: string) => {
      this.state[key] = state[key];
    });
  };

  private validateEmail = (data: FormValues): string => {
    if (!data.email) return 'Looks like you forgot to type in your email.';

    if (!EMAIL_VALIDATION_REGEX.test(data.email)) return 'That doesn’t look right, please try again!';

    return '';
  };

  private validateConfirmation = (data: FormValues): string => {
    if (!data.confirmation) return 'Please check the box to continue.';

    return '';
  };

  private validateCaptcha = (data: FormValues): string => {
    if (!data.captcha) return 'Captcha is required.';

    return '';
  };

  private validateForm = (): boolean => {
    const emailValidationMessage = this.validateEmail(this.formData);
    const confirmationValidationMessage = this.validateConfirmation(this.formData);
    const captchaValue = this.validateCaptcha(this.formData);

    const isInvalid = !!emailValidationMessage || !!confirmationValidationMessage || !!captchaValue;

    this.setState({
      validation: {
        email: emailValidationMessage,
        confirmation: confirmationValidationMessage,
        captcha: captchaValue,
      },
    });

    return !isInvalid;
  };

  private revalidateForm = (): void => {
    if (Object.values(this.state.validation).some((error) => error)) {
      this.validateForm();
    }
  };

  private handleChangeState = ({ name }) => {
    const currentValue = this.state[name];

    switch (name) {
      case 'isLoading': {
        if (this.ui.submitBtn) {
          (this.ui.submitBtn as HTMLButtonElement).disabled = currentValue;
        }
        break;
      }
      case 'step':
        this.handleStepChange(currentValue);
        break;
      case 'validation':
        this.handleValidationChange(currentValue);
        break;
      default:
        break;
    }
  };

  private handleFormSubmit = async (e: SubmitEvent): Promise<void> => {
    e.preventDefault();

    const apiUrl =  (this.ui.form as HTMLFormElement).action;

    const isValid = this.validateForm();

    this.requireCaptcha();

    if (!isValid) return;

    this.setState({
      isLoading: true,
    });

    try {
      await axios.post(apiUrl, { email: this.formData.email });

      this.handleSendAnalyticsEvent();

      this.setState({
        isLoading: false,
        step: 'success',
      });
    } catch (error) {
      Logger.catchError('Blog banner subscription failed', error);

      this.handleResetCaptcha();

      this.setState({
        isLoading: false,
      });
    }
  };

  private handleStepChange = (step: string) => {
    const formStep = this.rootElement.querySelector(`[data-step="${step}"]`);

    if (!formStep) return;

    this.rootElement.querySelectorAll(`.${BlogSubscribeBanner.formStepActiveClass}`).forEach((el) => {
      el.classList.remove(BlogSubscribeBanner.formStepActiveClass);
    });

    formStep.classList.add(BlogSubscribeBanner.formStepActiveClass);
  };

  private handleValidationChange = (validation: BlogSubscribeBannerState['validation']) => {
    const {
      emailInput,
      confirmationCheckbox,
      emailError,
      confirmationError,
      captchaError,
    } = this.ui;

    if (!emailError || !confirmationError || !emailInput || !confirmationCheckbox || !captchaError) return;

    if (validation.email) {
      emailInput.classList.add(BlogSubscribeBanner.inputErrorClass);
      emailError.classList.add(BlogSubscribeBanner.formValidationClass);
      emailError.textContent = validation.email;
    } else {
      emailInput.classList.remove(BlogSubscribeBanner.inputErrorClass);
      emailError.classList.remove(BlogSubscribeBanner.formValidationClass);
      emailError.textContent = '';
    }

    if (validation.confirmation) {
      confirmationCheckbox.classList.add(BlogSubscribeBanner.inputErrorClass);
      confirmationError.classList.add(BlogSubscribeBanner.formValidationClass);
      confirmationError.textContent = validation.confirmation;
    } else {
      confirmationCheckbox.classList.remove(BlogSubscribeBanner.inputErrorClass);
      confirmationError.classList.remove(BlogSubscribeBanner.formValidationClass);
      confirmationError.textContent = '';
    }

    if (validation.captcha) {
      captchaError.textContent = validation.captcha;
      captchaError.classList.add(BlogSubscribeBanner.formValidationClass);
      captchaError.classList.add(BlogSubscribeBanner.formValidationClass);
    } else {
      captchaError.classList.remove(BlogSubscribeBanner.formValidationClass);
      captchaError.classList.remove(BlogSubscribeBanner.formValidationClass);
      captchaError.textContent = '';
    }
  };

  getCaptchaResponse() {
    if (this.captchaProvider.isCaptchaReady) {
      return window.grecaptcha.getResponse(this.captchaProvider.captchaBlogWidgetId);
    } else {
      Logger.catchError('recaptcha script failed to load');

      return '';
    }
  }

  handleResetCaptcha() {
    if (this.captchaProvider.isCaptchaReady) {
      window.grecaptcha.reset(this.captchaProvider.captchaBlogWidgetId);
    } else {
      Logger.catchError('recaptcha script failed to reset');
    }
  }

  requireCaptcha() {
    if (this.ui.captchaContainer) this.ui.captchaContainer.classList.remove('d-none');
  }

  private handleSendAnalyticsEvent = () => {
    analytics.trackEvent({
      ...events.EMAIL_SUBSCRIPTION,
      eventLabel: 'Banner',
    });
  };
}

export default BlogSubscribeBanner;
