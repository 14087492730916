import DOMPurify from 'dompurify';
import UiElement from '../../../shared/ui-element/ui-element';
import config from '../../../shared/config/config';
import analytics, { events } from '../../../shared/analytics';
import request from '../../utils/request/request';
import Logger from '../../utils/logger/logger';

/*
* Redirect timeout for analytics
* Should prevent analytics requests from canceling on redirect after email check
* */
const REDIRECT_TIMEOUT = 300;

class SignupFormStepEmail extends UiElement {
  ui = {
    inputEmail: '.js-email-input',
    submitButton: '.js-email-form-step-submit',
    formError: '.js-email-form-step-error',
  };

  events = {
    'invalid @ui.inputEmail': 'handleEmailInputValidation',
    'submit @rootElement': 'handleFormStepSubmit',
  };

  constructor(options = {}) {
    super({ rootElement: '#signup-form-step-email' });

    this.onSubmit = options.onSubmit;
    this.isEduEmailValidationNeeded = options.isEduEmailValidationNeeded;
  }

  showEmailFieldError = (errorText) => {
    this.ui.inputEmail.nextElementSibling.innerHTML = DOMPurify.sanitize(errorText);
    this.ui.inputEmail.classList.add('form-group__form-control_invalid');
  };

  showFormError = (errorText) => {
    this.ui.formError.textContent = errorText;
    this.ui.formError.classList.add('d-block');
  };

  hideErrors = () => {
    this.ui.inputEmail.classList.remove('form-group__form-control_invalid');
    this.ui.formError.classList.remove('d-block');
  };

  handleEmailInputValidation(e) {
    e.preventDefault();
    const emailInput = this.ui.inputEmail;

    if (emailInput.validity.valueMissing) {
      this.showEmailFieldError(emailInput.dataset.errorEmpty);

      return;
    }

    if (emailInput.validity.typeMismatch) {
      this.showEmailFieldError(emailInput.dataset.errorNotEmail);
    }
  }

  checkIsEmailUnique = (email) => {
    const data = { body: { email, validate_edu: this.isEduEmailValidationNeeded() } };

    return request.post(config.get('customerAccountAPI.checkUniqueEmail'), data);
  };

  setProcessing = (isProcessing) => {
    this.rootElement.classList.toggle('signup-form_pending-status', isProcessing);
    this.ui.submitButton.disabled = isProcessing;
  };

  handleEmailCheckError = (error) => {
    if (error.response) {
      this.handleEmailCheckErrorResponse(error);
    } else {
      this.showFormError(this.rootElement.dataset.defaultError);
      Logger.catchError('Email check error', error);
    }
    this.setProcessing(false);
  };

  handleEmailCheckErrorResponse = (error) => {
    const responseCode = error.response.status;
    if (responseCode === 409) {
      analytics.trackEvent(events.SIGNUP_FORM_STEP_EMAIL_EXISTING);
      setTimeout(() => {
        if (window.location.pathname.includes('special-offer')) {
          window.location.pathname = 'gift-cards-partnership';
        } else {
          window.location.assign(config.get('customerAccountPages.login'));
        }
      }, REDIRECT_TIMEOUT);
    } else if (responseCode === 400) {
      this.showEmailFieldError(this.ui.inputEmail.dataset.errorNotEmail);
    } else if (responseCode === 404) {
      this.showEmailFieldError(this.ui.inputEmail.dataset.errorNotEduEmail);
    } else {
      this.showFormError(this.rootElement.dataset.defaultError);
      Logger.catchError('Email check error response', error);
    }
  };

  handleFormStepSubmit = (e) => {
    e.preventDefault();
    if (!this.rootElement.checkValidity()) {
      return;
    }

    const email = e.currentTarget.elements.email.value;
    this.setProcessing(true);
    this.checkIsEmailUnique(email)
      .then(() => {
        analytics.trackEvent(events.SIGNUP_FORM_STEP_EMAIL_NEW);
        this.setProcessing(false);
        this.onSubmit({ email });
      })
      .catch(this.handleEmailCheckError);
    this.hideErrors();
  };
}

export default SignupFormStepEmail;
