const categories = {
  SIGN_UP: 'Sign Up Page',
  DOWNLOAD: 'Download Page',
  GLOBAL: 'Global Actions',
  PERSONAL_PRICING: 'Pricing Page',
  BLOG_ARTICLE: 'Blog Article',
  WAR_BANNER: 'War Banner',
  IOS_LANDING: 'iOS Landing',
  PODCASTS: 'Podcasts',
  SPECIAL_OFFER: 'Partnership MC 1P',
  VENDOR_APP_PAGE: 'Vendor App Page',
  FAMILY_LANDING: 'Family Landing',
  GIFT_CARD_LANDING: 'Gift Card Landing',
  EMAIL_SUBSCRIPTION: 'Email Subscription',
};

const events = {
  SIGN_UP_EMAIL_OPT_IN: {
    eventCategory: categories.SIGN_UP,
    eventAction: 'Email Opt-In',
    eventNonInteraction: true,
  },
  SIGN_UP_SUCCESS: {
    eventCategory: categories.SIGN_UP,
    eventAction: 'Sign Up Success',
    eventLabel: 'Email',
    eventNonInteraction: true,
  },
  INSTALLER_AUTO_DOWNLOAD_START: {
    eventCategory: categories.DOWNLOAD,
    eventAction: 'Download Start',
    eventLabel: 'Auto',
    eventNonInteraction: true,
  },
  SIGNUP_FORM_OPEN: {
    eventCategory: categories.SIGN_UP,
    eventAction: 'Open Form',
    eventNonInteraction: true,
  },
  SIGNUP_FORM_CREATE_ACCOUNT: {
    eventCategory: categories.SIGN_UP,
    eventAction: 'Create Account',
    eventNonInteraction: true,
  },
  SIGNUP_FORM_STEP_EMAIL_NEW: {
    eventCategory: categories.SIGN_UP,
    eventAction: 'Enter Email',
    eventLabel: 'New Email',
    eventNonInteraction: true,
  },
  SIGNUP_FORM_STEP_EMAIL_EXISTING: {
    eventCategory: categories.SIGN_UP,
    eventAction: 'Enter Email',
    eventLabel: 'Existing Email',
    eventNonInteraction: true,
  },
  SIGNUP_FORM_STEP_PASSWORD: {
    eventCategory: categories.SIGN_UP,
    eventAction: 'Create Password',
    eventNonInteraction: true,
  },
  SIGNUP_FORM_STEP_USERNAME: {
    eventCategory: categories.SIGN_UP,
    eventAction: 'Create Nickname',
    eventNonInteraction: true,
  },
  SIGNUP_FORM_SOCIAL_AUTH_GOOGLE: {
    eventCategory: categories.SIGN_UP,
    eventAction: 'Click Google',
    eventNonInteraction: true,
  },
  SIGNUP_FORM_SOCIAL_AUTH_APPLE: {
    eventCategory: categories.SIGN_UP,
    eventAction: 'Click Apple',
    eventNonInteraction: true,
  },
  SEARCH_USED: {
    eventCategory: categories.GLOBAL,
    eventAction: 'Search Used',
    eventNonInteraction: false,
  },

  CONTENT_UP_CLICK: {
    eventCategory: categories.BLOG_ARTICLE,
    eventAction: 'Content Up Click',
    eventNonInteraction: false,
  },
  WAR_BANNER_CLOSE: {
    eventCategory: categories.WAR_BANNER,
    eventAction: 'Close Click',
    eventNonInteraction: false,
  },
  IOS_LOGO_TOP_CLICK: {
    eventCategory: categories.IOS_LANDING,
    eventAction: 'Logo Click',
    eventLabel: 'Top',
    eventNonInteraction: false,
  },
  IOS_LOGO_BOTTOM_CLICK: {
    eventCategory: categories.IOS_LANDING,
    eventAction: 'Logo Click',
    eventLabel: 'Bottom',
    eventNonInteraction: false,
  },
  IOS_WHO_FOR_CLICK: {
    eventCategory: categories.IOS_LANDING,
    eventAction: 'Who For Click',
    eventNonInteraction: false,
  },
  IOS_GIF_CLICK: {
    eventCategory: categories.IOS_LANDING,
    eventAction: 'Gif Click',
    eventNonInteraction: false,
  },
  IOS_APP_CLICK: {
    eventCategory: categories.IOS_LANDING,
    eventAction: 'App Click',
    eventNonInteraction: false,
  },
  IOS_SHOW_MORE_CLICK: {
    eventCategory: categories.IOS_LANDING,
    eventAction: 'Show More Click',
    eventNonInteraction: false,
  },
  IOS_ALL_DEVICES_REASON_CLICK: {
    eventCategory: categories.IOS_LANDING,
    eventAction: 'All Devices Reason Click',
    eventNonInteraction: false,
  },
  IOS_QUESTION_CLICK: {
    eventCategory: categories.IOS_LANDING,
    eventAction: 'Question Click',
    eventNonInteraction: false,
  },
  IOS_SIGNUP_CLICK: {
    eventCategory: categories.IOS_LANDING,
    eventAction: 'Signup Click',
    eventNonInteraction: false,
  },
  PODCAST_SERVICE_CLICK: {
    eventCategory: categories.PODCASTS,
    eventAction: 'Click Listen On',
    eventNonInteraction: false,
  },
  SPECIAL_OFFER_BANNER_CLICK: {
    eventCategory: categories.SPECIAL_OFFER,
    eventAction: 'Click Banner',
    eventNonInteraction: false,
  },
  BLOG_BANNER_CLICK: {
    eventCategory: categories.BLOG_ARTICLE,
    eventAction: 'Click Banner',
    eventNonInteraction: false,
  },
  GIFT_CARD_PAY_NOW_CLICK: {
    eventCategory: categories.GIFT_CARD_LANDING,
    eventAction: 'Pay now',
    eventLabel: 'Click',
  },
  GIFT_CARD_PROCEED_TO_PAYMENT: {
    eventCategory: categories.GIFT_CARD_LANDING,
    eventAction: 'Proceed to payment',
    eventLabel: 'Click',
  },
  SPECIAL_OFFER_SIGNUP_CLICK: {
    eventCategory: categories.SPECIAL_OFFER,
    eventAction: 'Click Signup',
    eventNonInteraction: false,
  },
  SPECIAL_OFFER_BUY_GIFT_CARD_CLICK: {
    eventCategory: categories.SPECIAL_OFFER,
    eventAction: 'Click Buy Gift Card',
    eventNonInteraction: false,
  },
  SPECIAL_OFFER_REDEEM_CODE_CLICK: {
    eventCategory: categories.SPECIAL_OFFER,
    eventAction: 'Click Redeem Code',
    eventNonInteraction: false,
  },
  LAUNCH_APP_BUTTON_CLICK: {
    eventCategory: categories.VENDOR_APP_PAGE,
    eventAction: 'Click Launch App',
    eventNonInteraction: false,
  },
  DOWNLOAD_APP_BUTTON_CLICK: {
    eventCategory: categories.VENDOR_APP_PAGE,
    eventAction: 'Click Download Setapp',
    eventNonInteraction: false,
  },
  CLICK_GET_IOS: {
    eventCategory: categories.VENDOR_APP_PAGE,
    eventAction: 'Click Get iOS',
    eventNonInteraction: false,
  },
  FAMILY_LANDING_GET_NOW_CLICK: {
    eventCategory: categories.FAMILY_LANDING,
    eventAction: 'Get now',
    eventNonInteraction: false,
  },
  FAMILY_LANDING_EXPLORE_APPS_CLICK: {
    eventCategory: categories.FAMILY_LANDING,
    eventAction: 'Explore apps',
    eventNonInteraction: false,
  },
  FAMILY_LANDING_TRY_FREE_CLICK: {
    eventCategory: categories.FAMILY_LANDING,
    eventAction: 'Try free now',
    eventNonInteraction: false,
  },
  FAMILY_LANDING_TRY_PLAN_CLICK: {
    eventCategory: categories.FAMILY_LANDING,
    eventAction: 'Try plan',
    eventNonInteraction: false,
  },
  FAMILY_LANDING_VIEW_PLANS_CLICK: {
    eventCategory: categories.FAMILY_LANDING,
    eventAction: 'View Setapp plans',
    eventNonInteraction: false,
  },
  EMAIL_SUBSCRIPTION: {
    eventCategory: categories.EMAIL_SUBSCRIPTION,
    eventAction: 'Click',
  },
  GET_MEMBERSHIP_CLICK: {
    eventCategory: categories.VENDOR_APP_PAGE,
    eventAction: 'Click Get this app with Setapp',
  },
  BUY_NOW_SINGLE_APP_CLICK: {
    eventCategory: categories.VENDOR_APP_PAGE,
    eventAction: 'Click Buy Now this app',
  },
  TRY_FREE_SINGLE_APP_CLICK: {
    eventCategory: categories.VENDOR_APP_PAGE,
    eventAction: 'Click Try Free this app',
  },
};

export default events;
